<template>
  <div class="clients-table">
    <vx-card>
      <vue-bootstrap4-table
          :rows="data"
          :columns="columns"
          :config="config"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-change-per-page="onChangePerPage"
          :total-rows="total_rows"
          columnSelection>
        <template slot="active" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                  <vx-tooltip text="Inaktiv">
                    <span class="mega--circle red" v-if="props.row.active == '0'"></span>
                  </vx-tooltip>
                  <vx-tooltip text="Aktiver Kunde">
                    <span class="mega--circle green" v-if="props.row.active == '1'"></span>
                  </vx-tooltip>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">

                  <vx-tooltip text="Schnellkunde" v-if="props.row.fast_registration">
                    <feather-icon icon="Edit3Icon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                  </vx-tooltip>


                  <vx-tooltip text="Lead" v-if="props.row.type == 'lead'" style="color:orangered;">
                    <feather-icon icon="LoaderIcon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                  </vx-tooltip>
                </vs-col>
              </vs-row>


            </div>
          </div>
        </template>
        <template slot="contact_list" slot-scope="props">
          <div v-if="props.row.contacts">
            <ul>
              <li v-for="(contact, index) in props.row.contacts"
                  v-show="contact.first_name && contact.last_name && contact.pivot.active">
                <vx-tooltip :text="contact.pivot.position" position="left"
                            v-if="contact.pivot.position">
                  <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                  <a :href="`mailto:${contact.email}`">{{ contact.first_name }} {{
                      contact.last_name
                    }}</a>
                </vx-tooltip>
                <div v-if="!contact.pivot.position">
                  <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4"/>
                  <a :href="`mailto:${contact.email}`">{{ contact.first_name }} {{
                      contact.last_name
                    }}</a>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template slot="orders" slot-scope="props">
          <span class="vbt-table-td">
             {{ getNewestOrderDate(props.row.orders) }}
          </span>
        </template>

        <template slot="umsatz" slot-scope="props">
          <div
              v-if="props.row.reportings && (props.row.reportings.SalesReportThisYear != 0 || props.row.reportings.totalUnpaid  != 0 || props.row.reportings.sentOffers  != 0)">
            <span v-if="props.row.reportings.SalesReportThisYear != 0">Umsatz ({{ getCurrentYear() }}) {{
                parseCurrency(props.row.reportings.SalesReportThisYear)
              }} €</span><br>
            <span v-if="props.row.reportings.totalUnpaid  != 0"
                  style="color:darkred">Offen: {{ props.row.reportings.totalUnpaid }} €</span><br>
            <span v-if="props.row.reportings.sentOffers  != 0"
                  style="color:orange">Offene Angebote: {{ props.row.reportings.sentOffers }} €</span><br>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template slot="dsgvo_contract" slot-scope="props">
          <vx-tooltip text="Kein AV-Vertrag hinterlegt"
                      v-if="props.row.dsgvo_file == '' || props.row.dsgvo_file == null ">
            <span class="mega--circle red"></span>
          </vx-tooltip>
          <vx-tooltip text="AV-Vertrag vorhanden" v-else>
            <span class="mega--circle green"></span>
          </vx-tooltip>
        </template>

        <template slot="nda_contract" slot-scope="props">
          <vx-tooltip text="Kein AV-Vertrag hinterlegt"
                      v-if="props.row.nda_file == '' || props.row.nda_file == null ">
            <span class="mega--circle red"></span>
          </vx-tooltip>
          <vx-tooltip text="NDA-Vertrag vorhanden" v-else>
            <span class="mega--circle green"></span>
          </vx-tooltip>
        </template>

        <template slot="b2b_partner_contract_file" slot-scope="props">
          <vx-tooltip text="Kein B2Bsellers Partner-Vertrag hinterlegt"
                      v-if="props.row.b2b_partner_contract_file == '' || props.row.b2b_partner_contract_file == null ">
            <span class="mega--circle red"></span>
          </vx-tooltip>
          <vx-tooltip text="B2Bsellers Partner-Vertrag vorhanden" v-else>
            <span class="mega--circle green"></span>
          </vx-tooltip>
        </template>

        <template slot="row_actions" slot-scope="props">
          <div class="column-actions-group">
            <router-link :to="'/customers/'+props.row.id">
              <vx-tooltip text="Bearbeiten" position="left">
                <vs-button color="warning" type="filled" icon="edit" size="small"
                           :to="'/customers/'+props.row.id"></vs-button>
              </vx-tooltip>
            </router-link>
            <vx-tooltip text="Deaktivieren" position="left" v-if="props.row.active">
              <vs-button color="danger"
                         type="filled"
                         icon="pause"
                         size="small"
                         @click="toggleClientActive(props.row.id, props.row.active)"></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Aktivieren" position="left" v-if="!props.row.active">
              <vs-button color="success"
                         type="filled"
                         icon="check"
                         size="small"
                         @click="toggleClientActive(props.row.id, props.row.active)"></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Löschen" position="left">
              <vs-button color="primary"
                         type="filled"
                         icon="delete_forever"
                         size="small"
                         @click="openDeleteConfirm(props.row.id)"></vs-button>
            </vx-tooltip>
          </div>
        </template>
        <template slot="empty-results">
          Keine Kunden gefunden.
        </template>
      </vue-bootstrap4-table>
    </vx-card>
    <div   v-if="userHasPermission('view_bank_transactions')">
      <vs-button color="success" class="ml-5 mt-3" @click="csvExportClients">Alle dargestellten Kunden exportieren</vs-button>
    </div>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";

import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import PriceHelper from "@/mixins/helper/price.helper";
import * as qs from "qs";
import Templates from "../../administration/email-templates/Templates";
import cloneDeep from "lodash/cloneDeep";
import {mapGetters} from "vuex";

export default {
  components: {
    Templates,
    VueBootstrap4Table
  },
  data() {
    return {
      data: [],
      currentPage: 1,
      actions: [
        {
          btn_text: "Hinzufügen",
          router_link: '/customers/add',
          color: 'success'
        }
      ],
      columns: [
        {
          label: "Aktiv",
          name: "active",
          slot_name: "active",
          filter: {
            type: "simple",
            placeholder: "1/0"
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Kunden-Nr.",
          name: "customer_number",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'customer-number-column',
          showCol: true,
        },
        {
          label: "Firma",
          name: "company_name",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        }, {
          label: "Stadt",
          name: "city",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Umsatz",
          slot_name: "umsatz",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        {
          label: "Mitarbeiter",
          slot_name: "contact_list",
          sort: false,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        {
          label: "E-Mail",
          name: "email",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: true,
        },
        {
          label: "Letzte Rechnung",
          name: "orders.order_date",
          slot_name: "orders",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
          formatter: this.formatDateTime,
          width: '220px',
          initial_sort: true,
          initial_sort_order: 'desc',
          filter: {
            type: 'date',
            mode: 'range'
          }
        },
        {
          label: "AV-Vertrag",
          slot_name: "dsgvo_contract",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },{
          label: "NDA-Vertrag",
          slot_name: "nda_contract",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        {
          label: "B2Bsellers Partner-Vertrag",
          slot_name: "b2b_partner_contract_file",
          sort: true,
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        }, {
          label: "B2Bsellers Partner-Level",
          slot_name: "b2b_partner_level",
          sort: true,
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        {
          label: "Erstellt am",
           name: "created_at",
          sort: true,
           formatter: this.formatDateTime,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          showCol: false,
        },
        {
          label: "Actions",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-right',
          column_text_alignment: 'text-right',
          column_classes: 'actions-column',
          showCol: true,
        }
      ],
      config: {
        per_page: 20,
        checkbox_rows: false,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        server_mode: true,
        page: 1
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 20,
        page: 1,
        order: [],
      },
      total_rows: 0,
      selectedForDialog: null
    }
  },
  computed: {
    ...mapGetters(['userHasPermission', 'workspace']),
    requestParams() {
      let params = this.queryParams;


      params.filters.push({
        expression: 'group',
        filter: [
          {
            name: 'type',
            text: 'lead',
            expression: 'exact',
            operator: 'or',
          },
          {
            name: 'type',
            text: 'client',
            expression: 'exact',
            operator: 'or',
          }
        ]
      })

      params = QueryHelper.parseRequestParams(params);
      params.includes =  "orders";

      return params;
    }
  },

  methods: {
    onChangePerPage(value) {
      this.config.per_page = value;
    },
    parseCurrency(value) {
      if (value === undefined || value === null) {
        return
      }
      return PriceHelper.floatPointToComma(value)
    },
    fetchData() {
      this.$vs.loading()

      ApiService.get('clients', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
        this.$vs.loading.close()
      }).catch((error) => {
        console.log(error);
      })
    },

    toggleClientActive(id, current) {

      const payload = {
        active: !parseInt(current)
      };

      ApiService.put('clients/' + id, payload).then(response => {
        this.fetchData();

        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Der Status wurde erfolgreich geändert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Status konnte nicht geändert werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Status konnte nicht geändert werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    openDeleteConfirm(id) {
      this.selectedForDialog = id;

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind Sie sich sicher dass dieser Kunde gelöscht werden soll?',
        accept: this.acceptDelete,
        acceptText: 'Löschen',
        cancelText: 'Abbrechen'
      })
    },
    acceptDelete() {
      ApiService.delete('clients/' + this.selectedForDialog).then((response) => {

        this.fetchData();

        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Der Kunde wurde erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Kunde konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Kunde konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.currentPage = this.queryParams.page;

      this.fetchData();
    },
    getCurrentYear() {
      var currentTime = new Date();
      return currentTime.getFullYear();
    },

    getNewestOrderDate(orders) {
      orders.forEach(item => {
        this.newestOrderDate = item.order_date
      })
      return  this.formatDate(this.newestOrderDate);
    },
    csvExportClients(){


      ApiService.get('clients', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
        this.$vs.loading.close()
      }).catch((error) => {
        console.log(error);
      })

      this.$vs.loading()
      ApiService.get('clients/csv-export', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then((response) => {
        this.$vs.loading.close()
        if(response.data.status === 'success') {
          this.$vs.notify({
            title: 'CSV Datei erfolgreich erstellt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          window.open(process.env.VUE_APP_API_URL+"document/"+this.workspace.id+"/clients-csv-export/"+response.data.data+"?download", '_blank')
          return;

        } else {
          return this.$vs.notify({
            title: 'Fehlgeschlagen',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }).catch((error) => {
        this.$vs.loading.close()
        return this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })

    },
  }
}

</script>

<style lang="scss">
.clients-table {
  .vbt-table-wrapper {
    .actions-column {
      width: 110px;
    }

    .customer-number-column {
      width: 190px;
    }
  }
  .daterangepicker.openscenter[data-v-267f4ee2] {
    left: 0;
  }
}
</style>
